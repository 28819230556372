import React, { useState } from 'react'
import { Link } from 'gatsby'
import CtaRow from 'components/cta-row'
import { documentIcon, documentTemplateIcon } from 'images/hulp/tools'
import Helmet from 'react-helmet'
import Layout from '../../../layouts'
import { articleImageBlue } from 'images/hulp'
import NumberFormat from 'react-number-format'
import axios from 'axios'

import config from '../../../config'
const { appUrl, signupRoute } = config

import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'

class calculatorPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      virgin: false,
      isLoading: false,
      license_plate: '',
      electric_vehicle: '',
      bijtellingspercentage: '',
      registration_year: '',
      maximum_lage_bijtelling: 0,
      bijtelling: 0,
      showCapField: false
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputChangeElectricVehicle = this.handleInputChangeElectricVehicle.bind(this);
    this.handleInputChange2 = this.handleInputChange2.bind(this);
  }


  handleInputChange(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value.replace(/\-/,"").toUpperCase()
    });
  }

  handleInputChangeElectricVehicle(event){
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;


    if(target.value == "false"){
      this.setState({
        bijtellingspercentage: 22,
        showCapField: false
      })
    }

    if(target.value == "true"){
      this.setState({
        bijtellingspercentage: 0
      })
    }

    this.setState({
      [name]: value.replace(/\-/,"")
    });
  }


  handleInputChange2(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let percentage;
    let cap;
    let cap1;

    switch (target.value) {
      case "2018":
        console.log('hi')
        percentage = 4;
        cap = 0;
        cap1 = false;
        break;
      case "2019":
        percentage = 4;
        cap = 50000;
        cap1 = true;
        break;
      case "2020":
         percentage = 8;
         cap = 45000;
         cap1 = true;
        break;
      case "2021":
        percentage = 12;
        cap = 40000;
        cap1 = true;
        break;
      case "2022":
        percentage = 16;
        cap = 35000;
        cap1 = true;
        break;
      case "2023":
        percentage = 16;
        cap = 30000;
        cap1 = true;
        break;
      case "2024":
        percentage = 16;
        cap = 30000;
        cap1 = true;
        break;
      case "2025":
        percentage = 17;
        cap = 30000;
        cap1 = true;
        break;
    }

    this.setState({
      bijtellingspercentage: percentage,
      maximum_lage_bijtelling: cap,
      showCapField: cap1
    })

    this.setState({
      [name]: value.replace(/\-/,"")
    });

  }



  handleSubmit = event => {
    event.preventDefault()

    this.setState({
      isLoading: true,
    })

    if (!this.state.virgin) {

      this.setState({
        virgin: false,
      })

      var url = 'https://opendata.rdw.nl/resource/m9d7-ebf2.json'
      axios
        .get(url, {
          params:{
            kenteken: this.state.license_plate
          }

        })
        .then(res => {

          if(res.data.length){


            const catalogusprijs = Number(res.data[0].catalogusprijs)
            const bijtellingspercentage = this.state.bijtellingspercentage / 100;
            const maximum_lage_bijtelling = this.state.maximum_lage_bijtelling;

            if(this.state.electric_vehicle == "false"){
              var bijtelling = ( catalogusprijs * bijtellingspercentage )/12;
            }

            if(this.state.electric_vehicle == "true"){
              if (maximum_lage_bijtelling == 0){
                var bijtelling =  catalogusprijs * bijtellingspercentage / 12;
              }
              else if(catalogusprijs > maximum_lage_bijtelling){
                var bijtelling =  (maximum_lage_bijtelling * bijtellingspercentage + (catalogusprijs - maximum_lage_bijtelling) * 0.22) / 12;
              } else{
                var bijtelling = ( catalogusprijs * bijtellingspercentage )/12;
              }
            }

            this.setState({
              isLoading: false,
              bijtelling: bijtelling,
              merk: res.data[0].merk,
              handelsbenaming: res.data[0].handelsbenaming,
              datum_eerste_toelating_dt: res.data[0].datum_eerste_toelating_dt.substring(0, 4),
              catalogusprijs: res.data[0].catalogusprijs
            })
          }
          else{
            console.log('no result')
            this.setState({
              isLoading: false,
              bijtelling: 0,
              merk: 'Geen resultaat',
              handelsbenaming: 'Geen resultaat',
              datum_eerste_toelating_dt: 'Geen resultaat',
              catalogusprijs: 0
            })
          }

        })
      }
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="tools animated fadeInPage">
          <Helmet>
            <title>
              Bereken snel je bijtelling in 2022 | Handige calculator | Employes
            </title>
            <meta
              name="description"
              content="Reken met een kenteken uit wat de bruto bijtelling is van een auto van de zaak. Ook geschikt voor elektrische voertuigen."
            />
            <meta
              itemprop="name"
              content="Bereken snel je bijtelling in 2022 | Handige calculator | Employes"
            />
            <meta
              itemprop="description"
              content="Reken met een kenteken uit wat de bruto bijtelling is van een auto van de zaak. Ook geschikt voor elektrische voertuigen."
            />
            <meta itemprop="image" content="/static/meta-bijtelling.jpg" />

            {/* Windows tags */}
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta
              name="msapplication-TileImage"
              content="static/favicon/mstile-144x144.png"
            />

            {/* OpenGraph tags */}
            <meta property="og:site_name" content="Bereken snel je bijtelling in 2022 | Handige calculator | Employes" />
            <meta property="og:url" content="https://employes.nl/tools/bijtelling-auto-van-de-zaak/" />
            <meta property="og:type" content="website" />

            <meta
              property="og:title"
              content="Bereken eenvoudig de bijtelling auto van de zaak | Handige calculator | Employes"
            />
            <meta
              property="og:description"
              content="Reken met een kenteken uit wat de bruto bijtelling is van een auto van de zaak. Ook geschikt voor elektrische voertuigen."
            />
            <meta
              property="og:image"
              content="https://employes.nl/static/meta-bijtelling.jpg"
            />
            <meta property="og:image:type" content="image/jpeg"/>

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:title"
              content="Bereken eenvoudig de bijtelling auto van de zaak | Handige calculator | Employes"
            />
            <meta
              name="twitter:description"
              content="Reken met een kenteken uit wat de bruto bijtelling is van een auto van de zaak. Ook geschikt voor elektrische voertuigen."
            />
            <meta
              name="twitter:image"
              content="https://employes.nl/static/meta-bijtelling.jpg"
            />
          </Helmet>

          <header className="padding-xl">
            <div className="container-md">
              <div className="grid yg center text-center">
                <div className="col-8">
                  <p className="eyebrow">Tool</p>
                  <h1>Bereken de bijtelling auto van de zaak</h1>
                  <p className="streamer center">
                    Bepaal de bijtelling van je auto van de zaak op basis van het kenteken.
                  </p>
                </div>
              </div>
            </div>
          </header>

          <section className="tools-cards padding-xxl">
            <div className="container-sm">
              <div className="grid yg">
                <div className="col-7">
                  <div className="title margin-m-bottom">
                    <h2>Vul het kenteken in</h2>
                    <p>
                      Benieuwd wat jij aan bijtelling gaat betalen voor je leaseauto? Met deze makkelijke calculator weet je het direct. Voer je kenteken in en geef aan of het een elektrische auto is of niet. Daarna zie je direct wat de bruto bijtelling per maand is.
                    </p>
                  </div>

                  <form onSubmit={this.handleSubmit}>
                    <div className="grid yg">

                      <div className="col-12 no-mar">
                        <div className="form-group">
                          <label>Kenteken (zonder streepjes)</label>
                          <input
                            name="license_plate"
                            value={this.state.license_plate}
                            onChange={this.handleInputChange}
                            type="text"
                            className="form-label"
                            id="kenteken"
                            placeholder="Vul het kenteken in"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-6 no-mar">
                        <div className="form-group">
                          <label>Elektrische auto</label>
                          <select
                            name="electric_vehicle"
                            value={this.state.electric_vehicle}
                            onChange={this.handleInputChangeElectricVehicle}
                            className="form-label"
                            id="electric_vehicle"
                          >
                            <option value="select_electric">Selecteer keuze</option>
                            <option value="false">Nee</option>
                            <option value="true">Ja</option>
                          </select>
                        </div>
                      </div>

                      {this.state.electric_vehicle == "true" ? (
                        <div className="col-6 no-mar">
                          <div className="form-group">
                            <label>Eerste tenaamstelling in</label>
                            <select
                              name="registration_year"
                              value={this.state.registration_year}
                              onChange={this.handleInputChange2}
                              className="form-label"
                              id="registration_year"
                            >
                              <option value="select_year">Selecteer jaar</option>
                              <option value="2018">2018</option>
                              <option value="2019">2019</option>
                              <option value="2020">2020</option>
                              <option value="2021">2021</option>
                              <option value="2022">2022</option>
                              <option value="2023">2023</option>
                              <option value="2024">2024</option>
                              <option value="2025">2025</option>
                            </select>
                          </div>
                        </div>
                      ) : null }

                      <div className="col-6 no-mar">
                        <div className="form-group">
                          <label>
                            {this.state.electric_vehicle == "true" ? ( "Lage bijtellingspercentage" ) : ( "Bijtellingspercentage" )}
                          </label>
                          <input
                            name="bijtellingspercentage"
                            value={this.state.bijtellingspercentage}
                            onChange={this.handleInputChange}
                            type="number"
                            min="0"
                            className="form-label"
                            id="bijtellingspercentage"
                            placeholder="%"
                            required
                          />
                        </div>
                      </div>

                      {this.state.showCapField == true ? (
                        <div className="col-6 no-mar">
                          <div className="form-group">
                            <label>
                              Maximaal bedrag (voor lage bijtelling)
                            </label>
                            <input
                              name="maximum_lage_bijtelling"
                              value={this.state.maximum_lage_bijtelling}
                              onChange={this.handleInputChange}
                              type="number"
                              min="0"
                              className="form-label"
                              id="bijtellingspercentage"
                              placeholder="%"
                              required
                            />
                          </div>
                        </div>
                      ) : null }

                    </div>

                    <button
                      type="submit"
                      className="btn primary margin-m-top lg flex right"
                    >
                      Bereken de bijtelling
                    </button>
                  </form>
                </div>

                <div className="col-5">
                  <div className="tools-cards-single proforma-result card right">
                    {this.state.isLoading ? (
                      <div className="proforma-loader-container">
                        <Loader
                          type="Oval"
                          color="#e53383"
                          height={80}
                          width={80}
                          timeout={5000}
                        />
                      </div>
                    ) : null}

                    {!this.state.isLoading ? (
                      <div className="proforma-result-content">
                        <p className="eyebrow center margin-xs-top">
                          Bijtelling
                        </p>

                        <div className="price-container margin-m-bottom">
                          <span className="price">
                            <h2 className="no-margin padding-xs-bottom">
                              <NumberFormat
                                value={this.state.bijtelling}
                                decimalScale={0}
                                fixedDecimalScale={true}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                allowedDecimalSeparators={','}
                                decimalSeparator={','}
                              />,-
                              <small>per maand</small>
                            </h2>
                            <p className="small">Dit is het bruto bedrag per maand.</p>
                          </span>
                        </div>

                        <h6 className="margin-s-top">
                          Gegevens van de auto
                        </h6>
                        <div className="proforma-result-item">
                          <span>Merk:</span>
                          <span>
                          {this.state.merk ? ( this.state.merk ) : '-' }
                          </span>
                        </div>

                        <div className="proforma-result-item">
                          <span>Model:</span>
                          <span>
                          {this.state.handelsbenaming ? ( this.state.handelsbenaming ) : '-' }
                          </span>
                        </div>

                        <div className="proforma-result-item">
                          <span>Jaar:</span>
                          <span>
                          {this.state.datum_eerste_toelating_dt ? ( this.state.datum_eerste_toelating_dt ) : '-' }
                          </span>
                        </div>

                        <div className="proforma-result-item">
                          <span>Catalogusprijs:</span>
                          <span>
                            €{' '}
                            <NumberFormat
                              value={this.state.catalogusprijs}
                              decimalScale={0}
                              fixedDecimalScale={true}
                              displayType={'text'}
                              thousandSeparator={'.'}
                              allowedDecimalSeparators={','}
                              decimalSeparator={','}
                            />,-
                          </span>
                        </div>


                        <div className="margin-xs-top">
                          <p
                            style={{
                              fontSize: 12 + 'px',
                              lineHeight: 16 + 'px',
                            }}
                          >
                            *Let op, het gaat om een voorbeeld&shy;berekening. Er kunnen geen rechten aan ontleend worden.
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="cta-card right">
                    <img
                      src={articleImageBlue}
                      alt="Backgrounnd image of the card"
                    />
                    <p className="eyebrow">Probeer nu gratis</p>
                    <h5 className="margin-s-bottom">
                      Ervaar het gemak en probeer Employes gratis uit.
                    </h5>
                    <a href={appUrl + signupRoute} className="btn primary">
                      Probeer nu
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="vision padding-l-top padding-xl-bottom">
            <div className="container-sm">
              <div className="grid yg">
                <div className="col-12">
                  <p className="eyebrow">Toelichting</p>
                  <h2>Bijtelling van je auto berekenen?</h2>
                </div>

                <div className="col-6">
                  <p className="margin-s-bottom">
                  De bijtelling is gebaseerd op een percentage van de catalogusprijs van de auto en is afhankelijk van de CO2-uitstoot. Deze uitstootgrenzen worden ieder jaar opnieuw vastgesteld door de belastingdienst. In 2025 zijn er twee tarieven voor de bijtelling: 17% en 22%. Wil jij de bijtelling van 2025 berekenen? Door het kenteken in te voeren in de bijtellingscalculator kun je direct zien wat jouw maandlasten zijn.
                  </p>

                  <h5>Bijtelling voor elektrische auto's</h5>
                  <p className="margin-s-bottom">
                  Is de bijtelling van elektrische auto’s auto’s voordeliger? Ja, voor een elektrische auto geldt in 2025 het lage tarief, dus de bijtelling van 16%. Dit geldt tot een bedrag van € 30.000 euro. Voor elke euro hierboven betaal je 22% bijtelling. Voor een hybride auto betaal je wel het hogere tarief van 22% bijtelling.
                  </p>

                  <h5>Auto van de zaak</h5>
                  <p className="margin-s-bottom">
                  Rij jij een auto van de zaak? En wil je weten wat de bijtelling van deze auto is? Dan kun je dat eenvoudig berekenen met deze bijtellingscalculator. Aan de hand van de fiscale bijtelling van je leaseauto weet jij wat je maandlasten zijn.
                  </p>
                </div>
                <div className="col-6">
                  <h5>Bijtelling andere voertuigen</h5>
                  <p className="margin-s-bottom">
                  Heb jij een oldtimer of youngtimer? Dan is je auto ouder dan 15 jaar. Dan is de bijtelling van je auto 35% over de dagwaarde, de huidige waarde van je auto. In dit geval is de cataloguswaarde niet langer het uitgangspunt voor de bijtelling. Er zijn meerdere factoren die de waarde van je oldtimer kunnen bepalen, zoals de aanschafwaarde of de taxatiewaarde. Voor ondernemers is deze regeling daarom interessant!
                  </p>

                  <p className="seo-link">
                    Tags:&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratie-uitbesteden/"
                    >
                      Salarisadministratie uitbesteden
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarispakket/">
                      Salarispakket
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salaris-software/">
                      Salaris-software
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/personeelsadministratie/">
                      Personeelsadministratie
                    </Link>
                    ,&nbsp;
                    <Link
                      className="seo-link"
                      to="/salarisadministratie-zelf-doen/"
                    >
                      Salarisadministratie-zelf-doen
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/salarisverwerking/">
                      Salarisverwerking
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loon-software/">
                      Loon-software
                    </Link>
                    ,&nbsp;
                    <Link className="seo-link" to="/loonadministratie/">
                      Loonadministratie
                    </Link>

                  </p>
                </div>

              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default calculatorPage
